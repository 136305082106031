import {
  cancleLog,
  getImg
} from "../details/api";
import Clipboard from "clipboard";
import {
  addCart
} from "@/views/order/api";
import {sign} from '@/views/order/channelOrder/progress/api';
import {queryAppraise} from "@/views/order/progress/api";
import logisticsEvaluationModal from '@/views/order/components/logisticsEvaluationModal.vue'
import consulationModal from '@/views/order/components/consultationPopup.vue'
import { findOmsOrderStatus, getSalesOrderProcess, addShopmentDetail, getListCompany, getTracksList  } from "@/views/order/retailOrder/progress/api";

export default {
  name: "purchaseOrderprogress",
  components: {
    consulationModal,
    logisticsEvaluationModal
  },
  data() {
    return {
      showImg:true,
      pageLoadFlag: false,
      desc: ["非常不满意", "不满意", "一般", "满意", "非常满意"],
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/channelOrder/detail",
          name: "orderDetail",
          title: "订单详情"
        },
        {
          path: "/channelOrder/progress",
          name: "progress",
          title: "发货进度"
        }
      ],
      tabActiveIndex: 0,
      visible: false,
      orderDetail: {
        orderHeader: {}
      },
      evaluate: {
        productLevel: 3,
        deliveryLevel: 3,
        logisticsLevel: 3,
        evaluationContent: "",
        creator: "",
        createdDate: "",
        serviceLevel: 1,
        show: false, //是否显示评价
        finished: false //是否评价完成
      },
      tags: [{
          label: "结果满意",
          val: false
        },
        {
          label: "回复速度快",
          val: false
        },
        {
          label: "态度好",
          val: false
        }
      ],
      examInfo: {},
      erpList: [], //发货单列表
      isOuted: false, //是否是出库单
      selSapOrder: {
        sap: {},
        logistics: []
      }, //当前选中的套单
      // isDisabled:false,
      isLoginModalShow: false,
      cancleId: '',
      onluyOneMessage: false,
      imgURL: [],
      // imgURLItem:'',
      modelConsulation: false,
      modellogisticsEvaluation: false,
      addNumMaskVisible: false,
      giveUpModal:true,
      reportModal:true,
      consultationList: [], // 咨询详情
      evaluationObj:null,
      mapUrl:'',
      key: new Date().getTime(),
      showDisplay:false,
      isHdyxActiveFlag:false, // 回单影像是否可点击 (初始化接口请求)
      omsList:[],
      addVisibleLoading: false,
      addNumInput: "",
      addNumInputCopy: "",
      addNumSelect: undefined,
      addNumSelectOptions: []
    };
  },
  watch:{
    mapUrl: {
      handler(val) {
        this.key = new Date().getTime()
        this.mapUrl= val
      },
      immediate: true,
    }
  },
  computed: {
    testList() {
      // 当数据不需要完全显示的时候
      if(this.showDisplay == false){
        let testList = []; // 定义一个空数组
        // 先显示前三个
        if(this.consultationList.length > 3){
          for(var i = 0;i < 3;i++){
            testList.push(this.consultationList[i])
          }
        }else{
          testList = this.consultationList;
        }
        return testList; // 返回当前数组
      }
      else{//展示全部数据
        return this.consultationList;
      }
    },
    totalProd: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += item.qty;
        });
      }
      return total;
    },
    totalPrice: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += (item.qty * (item.basePrice * 100)) / 100;
        });
      }
      return total;
    },
    totalLoadVolume: function () {
      let total = 0;
      if (this.selSapOrder && Array.isArray(this.selSapOrder.sap.prods)) {
        this.selSapOrder.sap.prods.forEach(item => {
          total += (
              ((item.sap.bactualQty || 0) *
                  ((item.sap.volume || 0) * 1000)) /
              1000
          )
        });
      }
      return total;
    },
    totalAdvancePrice: function () {
      let total = 0;
      if (this.orderDetail && Array.isArray(this.orderDetail.orderLines)) {
        this.orderDetail.orderLines.forEach(item => {
          total += item.advancePrice;
        });
      }
      return total;
    }
  },
  mounted() {
    if(this.$route.query.name == '销售订单进度汇总') {
      this.breadcrumbData[1].path = '/channelOrder/salesProgress'
      this.breadcrumbData[1].title = '销售订单进度汇总'
    }
    
    else if(this.$route.query.name == '销售订单列表') {
      this.breadcrumbData[1].path = '/channelOrder/orderList'
      this.breadcrumbData[1].title = '销售订单列表'
    } else {
      this.breadcrumbData[1].path = "/channelOrder/detail",
        this.breadcrumbData[1].title = "订单详情"
    }
    if (this.$route.query.id) {
      this.getDetail(this.$route.query.id);
      this.getLogisticsCompany();
    } else {
      // this.getDetail();
      this.$notification.warning({
        message: "订单号错误",
        duration: 2
      });
    }
  },
  methods: {
    // 获取物流公司列表
    getLogisticsCompany(){
      getListCompany({}).then(res => {
        let result;
        if(typeof res.data == 'string'){
          result = JSON.parse(res.data);
        }else{
          result = res.data;
        }
        if(result.data && result.data.length > 0){
          this.addNumSelectOptions = result.data;
        }
      })
    },
    // 查询咨询评价记录接口
    queryAppraise() {
      queryAppraise({
        orderCode: this.orderDetail.documentNum
      }).then(res => {
        // const { code, msg, type1, type3 } = res.data
        if(res.data.code == 0) {
          this.consultationList = res.data.type1
          if(JSON.stringify(res.data.type3) != '{}') {
            if( res.data.type3) {
              res.data.type3.tagNameList = res.data && res.data.type3 && res.data.type3.tagNames ? res.data.type3.tagNames.split(',') : []
            }
            this.evaluationObj = res.data.type3

          }else {
            this.evaluationObj = null
          }
        }else {
          this.$message.warning( res.data.msg || '系统异常，请稍后重试')
        }
      })
    },
    handleAddOk(){
      if(!this.addNumInput){
        this.$message.warning('请输入快递单号')
      }
      if(!this.addNumSelect){
        this.$message.warning('请选择快递公司')
      }
      this.addVisibleLoading = true;
      addShopmentDetail({
        orderId: this.orderDetail.id,
        shipmentSerialNo: this.addNumInput,
        shipmentName: this.addNumSelect
      }).then(res => {
        this.addVisibleLoading = false;
        let result;
        if(typeof res.data == "string"){
          result = JSON.parse(res.data);
        }else{
          result = res.data;
        }
        if(result.code == 0){
          this.$message.success("补充运单号成功");
          this.addNumMaskVisible = false;
          this.getDetail(this.orderDetail.id);
        }else{
          this.$message.warning(result.msg)
        }
      }).catch(() => this.addVisibleLoading = false);
    },
    handleAddCancel(){
      this.addNumMaskVisible = false;
    },
    addNum(){
      this.addNumMaskVisible = true;
    },
    openPJ(){
      this.modellogisticsEvaluation = true
    },
    openZX(){
      this.modelConsulation=true
    },
    // 投诉举报
    getInfo(formData){
      this.getDetail(this.$route.query.id);
      // console.log(form)
      // 提交咨询表单
    },
    getpjInfo(){
      this.getDetail(this.$route.query.id);
    },
    // 回单影像
    requestImg(documentNum) {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        documentNum
      }
      getImg(data).then(res => {
        if (res.data) {
          var realData = JSON.parse(res.data);
          if (realData.data) {
            this.imgURL = realData.data;
            // this.imgURL = [
            //   'http://archetype-hisense-dwlpt-vtradex-dev.devapp.hisense.com/ImageViewServlet?id=14961',
            //   'http://archetype-hisense-dwlpt-vtradex-dev.devapp.hisense.com/ImageViewServlet?id=14962',
            //   'https://xtwtest.hisense.com/myimg/productImages/1100101/3451028/180-180/3451028_0.jpg'
            // ]
            // console.log(54545,realData.data)
            // this.imgURLItem = realData.data[0];
            this.isHdyxActiveFlag = true;
            // this.onluyOneMessage = true;
          } else {
            // this.$message.warning('暂无回单影像');
            this.isHdyxActiveFlag = false;
          }
        } else {
          // this.$message.warning('暂无回单影像');
          this.isHdyxActiveFlag = false;
        }
      })
    },
    openImg(){
      if(this.isHdyxActiveFlag){
        this.onluyOneMessage = true;
      }else{
        this.$message.warning('暂无回单影像');
      }
    },
    //  取消物料
    canclelogistics(id) {
      this.isLoginModalShow = true;
      this.cancleId = id;
    },
    userLayout() {
      this.canclewuliu();
    },
    // 取消物流
    canclewuliu() {
      let data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        stvId: this.cancleId,
        invStatusType: ''
      }
      this.pageLoadFlag = true;
      cancleLog(data).then(res => {
        var realData = JSON.parse(res.data);
        this.pageLoadFlag = false;
        if (realData.code == '1') {
          this.$message.warning(realData.msg);
        } else {
          this.$message.success(realData.msg);
          this.getDetail(this.$route.query.id);
        }
      })
    },
    // 加入购物车
    addCart(proid, orgid) {
      let data = {
        productId: proid,
        orgId: orgid,
        num: 1
      };
      addCart(data)
        .then(res => {
          if (res.data.code == 0) {
            this.$message.success("加入购物车成功");
          } else {
            this.$message.warning(res.data.msg);
          }
        })
        .catch(error => {
          this.$message.warning("加入购物车失败");
          console.log("加入购物车", error);
        });
    },
    getProdImg() {
      return (
        this.selSapOrder.sap.materialGroup +
        "/" +
        this.selSapOrder.sap.productCode +
        "/180-180/" +
        this.selSapOrder.sap.picture
      );
    },
    getProdErrorImg() {
      return (
        "/assets/new/images/product/" +
        this.selSapOrder.sap.materialGroup +
        ".jpg"
      );
    },
    // 获取物流轨迹
    getOms(orderCode){
      this.pageLoadFlag = true
      this.omsList = []
      findOmsOrderStatus({
        stvNum:orderCode,
        loginType:'',
        cisCode:this.$store.state.user.userInfo.customer.cisCode,
      }).then(responese=>{
        this.omsList = JSON.parse(responese.data).code == 0?JSON.parse(responese.data).data.stvModelObj.omsOrderStatus:[]
        this.pageLoadFlag = false
      })
    },
     // 获取签名
     getSign(data){
      return sign(data)
    },
    getDetail(id) {
      this.pageLoadFlag = true;
      getSalesOrderProcess(id)
        .then(async(res) => {
          if (typeof res.data == "string") {
            this.orderDetail = JSON.parse(res.data).data;
          } else {
            this.orderDetail = res.data.data;
          }
          this.addNumInput = this.orderDetail.shipmentSerialNo;
          this.addNumInputCopy = this.orderDetail.shipmentSerialNo;
          this.addNumSelect = this.orderDetail.shipmentName ? this.orderDetail.shipmentName : undefined;
          this.sapOrderItems = [];
          this.queryAppraise()
          this.tabActiveIndex = 0;
          const erpList =[]
          this.orderDetail.outBoundItem.forEach(item => {
            let flag = true
            for(let it of erpList){
              if(it.status==item.status&&it.documentNum==item.documentNum){
                flag=false
                item.sap = item;
                item.sap.xj =(item.sap.bactualQty || 0) * (item.sap.price || 0);
                it.hj=it.hj+item.sap.xj
                it.xjProdNum = it.xjProdNum +(item.sap.bactualQty || 0);
                item.logistics = item.omsOrderStatus;
                // item.nowStatuses = item.omsOrderStatus[0]; //现在订单状态 O8,O11 时可以评价 开头是欧不是零
                // item.nowStatuses = item.omsOrderStatus[0]; //现在订单状态 O8,O11 时可以评价 开头是欧不是零
                it.prods.push(item)
              }
            }
            if(flag){
              item.sap = item;
              item.sap.xj = (item.sap.bactualQty || 0) * (item.sap.price || 0);
              item.hj =  item.sap.xj
              item.xjProdNum = (item.sap.bactualQty || 0);
              item.logistics = item.omsOrderStatus;
              // item.nowStatuses = item.omsOrderStatus[0]; //现在订单状态 O8,O11 时可以评价 开头是欧不是零
              item.prods=[item]
              erpList.push(item);
            }
          });
          this.erpList = erpList
          this.selSapOrder = this.erpList[0];
          const signData = await this.getSign({content:this.selSapOrder.sap.documentNum,systemSource:'DMS'})
          // this.mapUrl=`https://dwldev.hisense.com/route/index.html#/?orderNumber=${ this.selSapOrder.sap.documentNum }&systemSource=DMS&sign=${signData.data?signData.data:''}`  
          this.mapUrl=`https://tms.hisense.com/route/index.html#/?orderNumber=${ this.selSapOrder.sap.documentNum }&systemSource=DMS&sign=${signData.data?signData.data:''}`  
    
          if (this.selSapOrder && this.selSapOrder.sap) {
            this.isOuted = !!this.selSapOrder.sap.documentNum;
          }
          if(this.isOuted){
            this.requestImg(this.selSapOrder.sap.documentNum);
          }
          if(this.orderDetail.gicOutWarehouseType == '70' && this.orderDetail.dataSource == 'O2O'){
            if(this.orderDetail.shipmentSerialNo){
              this.erpList = this.erpList.concat({ shipmentSerialNo: this.orderDetail.shipmentSerialNo, shipmentCorpName: this.orderDetail.shipmentName })
            }else{
              // 获取物流信息
              this.getOms(this.selSapOrder.sap.documentNum )
            }
          }
        })
        .finally(() => {
          this.pageLoadFlag = false;
        });
    },
    goBack() {
      window.close()
    },
    async tabChange(index) {
      // this.isHdyxActiveFlag = false;
      if(this.erpList[index].shipmentSerialNo){
        return;
      }
      this.tabActiveIndex = index;
      this.selSapOrder = this.erpList[index];
      this.isOuted = !!this.selSapOrder.sap.documentNum;
      if(this.isOuted){
        this.requestImg(this.selSapOrder.sap.documentNum);
      }
      const signData = await this.getSign({content:this.selSapOrder.sap.documentNum,systemSource:'DMS'})
          
      // this.mapUrl=`https://dwldev.hisense.com/route/index.html#/?orderNumber=${ this.selSapOrder.sap.documentNum }&systemSource=DMS&sign=${signData.data?signData.data:''}`  
      this.mapUrl=`  https://tms.hisense.com/route/index.html#/?orderNumber=${ this.selSapOrder.sap.documentNum }&systemSource=DMS&sign=${signData.data?signData.data:''}`  
      this.getOms(this.selSapOrder.sap.documentNum )
      this.showImg = false;
      this.$nextTick(it=>{
        this.showImg = true
      })
      // this.getEvaluate();
    },
    handleOk(e) {
      this.visible = false;
    },
    changeChecked(item) {
      item.val = !item.val;
    },
    getPrice(item) {
      return ((item.basePrice || 0) * 100 * (item.qty || 0)) / 100;
    },
    copyOrder() {
      var clipboard = new Clipboard(".copy-box");
      clipboard.on("success", e => {
        this.$message.success("复制成功");
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", e => {
        // 不支持复制
        this.$message.warning("该浏览器不支持自动复制");
        // 释放内存
        clipboard.destroy();
      });
    }
  }
};